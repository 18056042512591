import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
function Dashboard(props) {
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-xxl-5">
                  <div className="d-flex flex-column h-100">
                    <div className="row dashbord_boxs">
                      <div className="col-md-4">
                        <div className="card card-animate">
                          <div className="card-body ">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="fw-medium text-muted mb-0">
                                  Pending Order
                                </p>
                                <h2 className="mt-4 ff-secondary fw-semibold">
                                  <span
                                    className="counter-value"
                                    data-target={100}
                                  >
                                    0
                                  </span>
                                </h2>
                              </div>
                              <div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-danger rounded-circle fs-2">
                                    <img
                                      src="assets/images/menu-icon-light4.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card card-animate">
                          <div className="card-body ">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="fw-medium text-muted mb-0">
                                  Complete Order
                                </p>
                                <h2 className="mt-4 ff-secondary fw-semibold">
                                  <span
                                    className="counter-value"
                                    data-target={10}
                                  >
                                    0
                                  </span>
                                  k
                                </h2>
                              </div>
                              <div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-success rounded-circle fs-2">
                                    <img
                                      src="assets/images/menu-icon-light4.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card card-animate">
                          <div className="card-body ">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="fw-medium text-muted mb-0">
                                  Customers
                                </p>
                                <h2 className="mt-4 ff-secondary fw-semibold">
                                  <span
                                    className="counter-value"
                                    data-target={10}
                                  >
                                    0
                                  </span>
                                  k
                                </h2>
                              </div>
                              <div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-primary rounded-circle fs-2">
                                    <img
                                      src="assets/images/menu-icon-light2.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card card-animate">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="fw-medium text-muted mb-0">
                                  Total Vote
                                </p>
                                <h2 className="mt-4 ff-secondary fw-semibold">
                                  <span
                                    className="counter-value"
                                    data-target="33.48"
                                  >
                                    0
                                  </span>
                                  %
                                </h2>
                              </div>
                              <div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-warning rounded-circle fs-2">
                                    <img
                                      src="assets/images/menu-icon-light5.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card card-animate">
                          <div className="card-body ">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="fw-medium text-muted mb-0">
                                  Product
                                </p>
                                <h2 className="mt-4 ff-secondary fw-semibold">
                                  <span
                                    className="counter-value"
                                    data-target={10}
                                  >
                                    0
                                  </span>
                                  k
                                </h2>
                              </div>
                              <div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-secondary rounded-circle fs-2">
                                    <img
                                      src="assets/images/menu-icon-light1.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
export default Dashboard;
