import React, { Fragment, useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import Moment from "moment";
import axios from "axios";
import { baseUrl } from "./BaseUrl";

const UserManagement = () => {
  const [record, setRecords] = useState([]);

  useEffect(() => {
    getUserData();
  }, []);

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "username",
      text: "User Name",
      className: "username",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span>
            {record.fname}{" "}
            {record.lname === "null" || record.lname === null
              ? ""
              : record.lname}
          </span>
        );
      },
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.email}</span>;
      },
    },

    {
      key: "phone",
      text: "Mobile No.",
      className: "phone",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.number}</span>;
      },
    },
    {
      key: "kyc_status",
      text: "Kyc Status",
      className: "status",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.kyc_status === "1" ? (
              <span>{"Verified"}</span>
            ) : (
              <span>{"Pending"}</span>
            )}
          </Fragment>
        );
      },
    },
    {
      key: "date",
      text: "Date",
      className: "date",
      align: "left",
      sortable: false,
      cell: (record) => {
        return <Fragment>{Moment(record.createdAt).format("lll")}</Fragment>;
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  const getUserData = () => {
    axios.get(baseUrl + "/adminapi/get-user").then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setRecords(respp.data);
        return false;
      }
    });
  };

  return (
    <>
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2 className="text-black">
                <b>USERS LIST</b>
              </h2>
            </div>

            <div className="white_box">
              <div className="product-list-outer customer-list-outer">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UserManagement;
