import React, { Fragment, useState, useEffect } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import { baseUrl } from "./BaseUrl";
import moment from "moment";
import { useParams } from "react-router-dom";

const PlayersView = () => {
  let { id } = useParams();

  const [record, setRecords] = useState([]);

  useEffect(() => {
    getPlayersById();
  }, []);

  const getPlayersById = () => {
    const data = {
      id: id,
    };
    axios.post(baseUrl + "/adminapi/get-playersById", data).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setRecords(respp.data);
      }
    });
  };

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "fname",
      text: "Name",
      className: "fname",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "sponsorship",
      text: "Sponsorship Type",
      className: "sponsorship",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount",
      className: "amount",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Purchased Date",
      className: "action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.purchaseDate).format("lll")}</Fragment>;
      },
    },
    // {
    //   key: "view",
    //   text: "Action",
    //   className: "view",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return (
    //       <Fragment>
    //         <Link to={"#"}>
    //           <button>View</button>
    //         </Link>
    //       </Fragment>
    //     );
    //   },
    // },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  return (
    <>
      <Header />
      <Navbar />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex flex-wrap justify-content-between align-items-center mb-4">
              <h2 className="text-black m-0">
                <b>Player Transaction</b>
              </h2>
            </div>
            <div className="white_box">
              <div className="job-table-outer">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayersView;
