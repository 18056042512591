import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import * as myConstList from "./BaseUrl";
import moment from "moment";
import { Modal } from "react-bootstrap";
const baseUrl = myConstList.baseUrl;

function PrivilegeCard(props) {
  const [refreshed, setRefreshed] = useState(false);
  const [record, setRecords] = useState([]);
  const [country, setCountry] = useState("");
  const [amount, setAmount] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenForUpdate, setModalIsOpenForUpdate] = useState(false);

  useEffect(() => {
    PrivilegeCardData();
  }, [refreshed]);

  const PrivilegeCardData = () => {
    axios.post(baseUrl + "/adminapi/privilegeCard", {}).then((res) => {
      var resp = res.data;
      if (resp.success === false) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.success === true) {
        setRecords(resp.data);
      }
    });
  };

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "country",
      text: "Country",
      className: "country",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount",
      className: "amount",
      align: "left",
      sortable: true,
    },

    {
      key: "createdAt",
      text: "date",
      className: "created_at",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.created_at).format("lll")}</Fragment>;
      },
    },
    // {
    //   key: "view",
    //   text: "Action",
    //   className: "view",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return (
    //       <Fragment>
    //         <Link to={"#"}>
    //           <button>View</button>
    //         </Link>
    //       </Fragment>
    //     );
    //   },
    // },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  const handleChangeName = async (event) => {
    if (event.target.name === "country") {
      let eventValue = event.target.value;
      setCountry(eventValue);
    }
    if (event.target.name === "amount") {
      let eventValue = event.target.value;
      setAmount(eventValue);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let customerData = {
      country: country,
      amount: amount,
    };
    axios
      .post(baseUrl + "/adminapi/add-privilege", customerData)
      .then((res) => {
        var resp = res.data;
        if (resp.success === true) {
          toast.success(resp.message);
          setAmount("");
          setCountry("");
          closeModal();
          PrivilegeCardData();
          return false;
        }
        toast.dismiss();
        toast.error(resp.message);
      });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex flex-wrap justify-content-between align-items-center mb-3">
              <h2 className="text-black">
                <b>Privilege Card</b>
              </h2>

              <div className="add-product-btn text-right">
                {/* <Link to={"/add-privilege-card"} className="btn btn-primary">
                  Add Privilege Card
                </Link> */}
                <button
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#add-tickets"
                  onClick={() => setModalIsOpen(true)}
                >
                  Add Privilege Card
                </button>
              </div>
            </div>
            <div className="white_box">
              <div className="row">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          className="job-form-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title> Add Play Contest</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Country</label>
                  <input
                    className="form-control"
                    type="text"
                    name="country"
                    onChange={handleChangeName}
                    value={country}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Amount</label>
                  <input
                    className="form-control fs12"
                    type="number"
                    name="amount"
                    onChange={handleChangeName}
                    value={amount}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <div className="submit-btn-outer">
              <button
                type="submit"
                class="btn btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <ToastContainer limit={1} />
        <footer refreshed={refreshed} setRefreshed={setRefreshed}></footer>
      </div>
    </div>
  );
}
export default PrivilegeCard;
