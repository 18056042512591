import React, { Fragment, useEffect, useState } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ReactDatatable from "@mkikets/react-datatable";
import Moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "./BaseUrl";

const PendingKYC = () => {
  const [record, setRecords] = useState([]);
  var show = 1;

  useEffect(() => {
    customerData();
  }, []);

  const customerData = () => {
    axios.get(baseUrl + "/adminapi/pending-kyc").then((res) => {
      var resp = res.data;
      if (resp.success === false) {
        return;
      }
      if (resp.success == true) {
        setRecords(resp.data);
      }
    });
  };

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "username",
      text: "User Name",
      className: "username",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <span>
            {record.fname}{" "}
            {record.lname === "null" || record.lname === null
              ? ""
              : record.lname}
          </span>
        );
      },
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.email}</span>;
      },
    },

    {
      key: "phone",
      text: "Mobile No.",
      className: "phone",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.number}</span>;
      },
    },
    {
      key: "kyc_status",
      text: "Identity Proof",
      className: "status",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.fimage_status === "1" ? "Pending" : ""}
            {record.fimage_status === "2" ? "Verified" : ""}
            {record.fimage_status === "0" ? "Rejected" : ""}
          </Fragment>
        );
      },
    },
    {
      key: "kyc_status",
      text: "Pancard",
      className: "status",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.pan_image_status === "1" ? "Pending" : ""}
            {record.pan_image_status === "2" ? "Verified" : ""}
            {record.pan_image_status === "0" ? "Rejected" : ""}
          </Fragment>
        );
      },
    },
    {
      key: "kyc_status",
      text: "Selfie",
      className: "status",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.selfie_status === "1" ? "Pending" : ""}
            {record.selfie_status === "2" ? "Verified" : ""}
            {record.selfie_status === "0" ? "Rejected" : ""}
          </Fragment>
        );
      },
    },
    {
      key: "date",
      text: "Date",
      className: "date",
      align: "left",
      sortable: false,
      cell: (record) => {
        return <Fragment>{Moment(record.createdAt).format("lll")}</Fragment>;
      },
    },
    {
      key: "view",
      text: "Action",
      className: "view",
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Link to={"/show-pending-kyc/" + record.id + "/" + show}>
              <img
                src="assets/images/edit-icon.png"
                alt=""
                className="img-fluid"
              />
            </Link>
          </Fragment>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };
  return (
    <>
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2 className="text-black">
                <b>PENDING KYC LIST</b>
              </h2>
            </div>
            <div className="white_box">
              <div className="row">
                <div className="product-list-outer customer-list-outer">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PendingKYC;
