import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import PendingKYC from "./components/PendingKYC";
import ShowPendingKyc from "./components/ShowPendingKyc";
import UserManagement from "./components/UserManagement";
import VerifiedKyc from "./components/VerifiedKyc";
import PrivilegeHistory from "./components/PrivilegeHistory";
import JobForm from "./components/JobForm";
import JobDetails from "./components/JobDetails";
import Tickets from "./components/Tickets";
import PrivilegeCard from "./components/PrivilegeCard";
import PrivilegeCardEdit from "./components/PrivilegeCardEdit";
import PrivilegeCardAdd from "./components/PrivilegeCardAdd";
import TicketsView from "./components/TicketsView";
import SupportNEarn from "./components/SupportNEarn";
import PlayersView from "./components/PlayersView";
import PlayNEarn from "./components/PlayNEarn";
import PlayNEarnView from "./components/PlayNEarnView";
import LearnNEarn from "./components/LearnNEarn";
import LearnNEarnView from "./components/LearnNEarnView";
import Promote from "./components/PromoteNEarn";

function App(props) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/pending-kyc" element={<PendingKYC />} />
        <Route path="/verified-kyc" element={<VerifiedKyc />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route
          path="/show-pending-kyc/:id/:show"
          element={<ShowPendingKyc />}
        />
        <Route
          path="/show-verified-kyc/:id/:show"
          element={<ShowPendingKyc />}
        />
        <Route path="/privilege-history" element={<PrivilegeHistory />} />
        <Route path="/job-form" element={<JobForm />} />
        <Route path="/job-form/job-details/:id" element={<JobDetails />} />
        <Route path="/privilege-card" element={<PrivilegeCard />} />
        <Route
          path="/privilege-card-edit/:id"
          element={<PrivilegeCardEdit />}
        />
        <Route path="/add-privilege-card" element={<PrivilegeCardAdd />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/tickets/viewTickets/:id" element={<TicketsView />} />
        <Route path="/support-earn" element={<SupportNEarn />} />
        <Route path="/support-earn/viewPlayers/:id" element={<PlayersView />} />
        <Route path="/promote-earn" element={<Promote />} />
        <Route path="/play-earn" element={<PlayNEarn />} />
        <Route path="/play-earn/viewContests/:id" element={<PlayNEarnView />} />
        <Route path="/learn-earn" element={<LearnNEarn />} />
        <Route path="/learn-earn/viewCourse/:id" element={<LearnNEarnView />} />
      </Routes>
    </Router>
  );
}
export default App;
