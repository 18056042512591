import React, { Fragment, useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { baseUrl } from "./BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const SupportNEarn = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenForUpdate, setModalIsOpenForUpdate] = useState(false);
  const [record, setRecords] = useState([]);
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [description, setDescription] = useState("");
  const [jerseyNo, setJerseyNo] = useState("");
  const [chestPrice, setChestPrice] = useState("");
  const [backPrice, setBackPrice] = useState("");
  const [leftPrice, setLeftPrice] = useState("");
  const [rightPrice, setRightPrice] = useState("");
  const [playerImage, setPlayerImage] = useState(false);
  const [jerseyImage, setJerseyImage] = useState(false);
  const [userUploadJerseyImage, setUserUploadJerseyImage] = useState(
    "/assets/images/file.png"
  );
  const [errorJerseyImage, setErrorJerseyImage] = useState("");

  const [userUploadPlayerImage, setUserUploadPlayerImage] = useState(
    "/assets/images/file.png"
  );
  const [errorPlayerImage, setErrorPlayerImage] = useState("");

  const [id, setId] = useState("");

  const dPic = "/assets/images/file.png";

  const closeModal = () => {
    setModalIsOpen(false);
    setName("");
    setJerseyNo("");
    setDob("");
    setLeftPrice("");
    setRightPrice("");
    setChestPrice("");
    setBackPrice("");
    setUserUploadPlayerImage(false);
    setUserUploadJerseyImage(false);
    setDescription("");
  };

  const openUpdateModal = (item) => {
    if (item.player_image === null || typeof item.player_image == "undefined") {
      var ImageUrl = "/assets/images/file.png";
    } else {
      var ImageUrl = baseUrl + "/static/upload/" + item.player_image;
    }

    if (item.jersey_image === null || typeof item.jersey_image == "undefined") {
      var ImageUrl1 = "/assets/images/file.png";
    } else {
      var ImageUrl1 = baseUrl + "/static/upload/" + item.jersey_image;
    }

    setName(item.player_name);
    setJerseyNo(item.jersey_no);
    setDob(item.dob);
    setLeftPrice(item.left_price);
    setRightPrice(item.right_price);
    setChestPrice(item.chest_price);
    setBackPrice(item.back_price);
    setUserUploadPlayerImage(ImageUrl);
    setUserUploadJerseyImage(ImageUrl1);
    setDescription(item.description);
    setId(item.id);

    setModalIsOpenForUpdate(true);
  };

  const closeUpdateModal = () => {
    setModalIsOpenForUpdate(false);
    setName("");
    setJerseyNo("");
    setDob("");
    setLeftPrice("");
    setRightPrice("");
    setChestPrice("");
    setBackPrice("");
    setUserUploadPlayerImage(false);
    setUserUploadJerseyImage(false);
    setDescription("");
  };
  const deleteRecord = (record) => {
    axios
      .post(baseUrl + "/adminapi/delete-player", { id: record.id })
      .then((resp) => {
        var respp = resp.data;

        if (respp.success === true) {
          getPlayers();
          closeModal();
          toast.success(respp.message);
        }
      });
  };
  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(getMethodDeleteId),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  useEffect(() => {
    getPlayers();
  }, []);

  const getPlayers = () => {
    axios.post(baseUrl + "/adminapi/get-players-data").then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setRecords(respp.data);
      }
    });
  };

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      setName(value);
      return false;
    }
    if (name === "jersey_no") {
      setJerseyNo(value);
      return false;
    }
    if (name === "chest_price") {
      setChestPrice(value);
      return false;
    }
    if (name === "back_price") {
      setBackPrice(value);
      return false;
    }
    if (name === "left_price") {
      setLeftPrice(value);
      return false;
    }
    if (name === "right_price") {
      setRightPrice(value);
      return false;
    }
    if (name === "dob") {
      setDob(value);
      return false;
    }
    if (name === "description") {
      setDescription(value);
      return false;
    }
  };

  const submit = (e) => {
    e.preventDefault();

    var playerPic = playerImage;
    var jerseyPic = jerseyImage;
    const formData = new FormData();

    formData.append("PlayerImage", playerPic);
    formData.append("JerseyImage", jerseyPic);
    formData.append("player_name", name);
    formData.append("jersey_no", jerseyNo);
    formData.append("chest_price", chestPrice);
    formData.append("back_price", backPrice);
    formData.append("left_price", leftPrice);
    formData.append("right_price", rightPrice);
    formData.append("dob", dob);
    formData.append("description", description);

    axios.post(baseUrl + "/adminapi/add-players", formData).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        closeModal();
        getPlayers();
        toast.success(respp.message);
        return false;
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };

  const onUpdate = () => {
    var playerPic = playerImage;
    var jerseyPic = jerseyImage;
    const formData = new FormData();

    formData.append("PlayerImage", playerPic);
    formData.append("JerseyImage", jerseyPic);
    formData.append("player_name", name);
    formData.append("jersey_no", jerseyNo);
    formData.append("chest_price", chestPrice);
    formData.append("back_price", backPrice);
    formData.append("left_price", leftPrice);
    formData.append("right_price", rightPrice);
    formData.append("dob", dob);
    formData.append("description", description);
    formData.append("id", id);

    axios.post(baseUrl + "/adminapi/update-player", formData).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        closeUpdateModal();
        getPlayers();
        toast.dismiss();
        toast.success(respp.message);
        return false;
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };

  const handleChangeFImage = (e) => {
    e.preventDefault();
    setPlayerImage("/assets/img/icon2.png");

    var IdentityPic = e.target.files[0];
    setPlayerImage(e.target.files[0]);

    setUserUploadPlayerImage(URL.createObjectURL(IdentityPic));

    if (IdentityPic === false) {
      setUserUploadPlayerImage(false);
      setErrorPlayerImage("Please select valid image jpg|jpeg|png|gif");

      return false;
    }
    if (!IdentityPic.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setUserUploadPlayerImage(false);
      setErrorPlayerImage("Please select valid image jpg|jpeg|png|gif");

      return false;
    }
    if (IdentityPic.size >= 1000000 || IdentityPic.size < 10000) {
      setErrorPlayerImage("Please select valid image jpg|jpeg|png|gif");
      return false;
    }
    // if (IdentityPic.size < 50000) {
    //     toast.error("File Size is less than 500KB")
    //     return false;
    // }
    setErrorPlayerImage("");
    setUserUploadPlayerImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleChangeJerseyImage = (e) => {
    e.preventDefault();
    setJerseyImage("/assets/img/icon2.png");

    var IdentityPic = e.target.files[0];
    setJerseyImage(e.target.files[0]);

    setUserUploadJerseyImage(URL.createObjectURL(IdentityPic));

    if (IdentityPic === false) {
      setUserUploadJerseyImage(false);
      setErrorJerseyImage("Please select valid image jpg|jpeg|png|gif");

      return false;
    }
    if (!IdentityPic.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setUserUploadJerseyImage(false);
      setErrorJerseyImage("Please select valid image jpg|jpeg|png|gif");

      return false;
    }
    if (IdentityPic.size >= 1000000 || IdentityPic.size < 10000) {
      setErrorJerseyImage("Please select valid image jpg|jpeg|png|gif");
      return false;
    }
    // if (IdentityPic.size < 50000) {
    //     toast.error("File Size is less than 500KB")
    //     return false;
    // }
    setErrorJerseyImage("");
    setUserUploadJerseyImage(URL.createObjectURL(e.target.files[0]));
  };

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "player_name",
      text: "Player Name",
      className: "player_name",
      align: "left",
      sortable: true,
    },
    {
      key: "jersey_no",
      text: "Jersey No.",
      className: "jersey_no",
      align: "left",
      sortable: true,
    },

    {
      key: "chest_price",
      text: "Chest Price",
      className: "chest_price",
      align: "left",
      sortable: true,
    },
    {
      key: "back_price",
      text: "Back Price",
      className: "back_price",
      align: "left",
      sortable: true,
    },
    {
      key: "left_price",
      text: "Left Shoulder Price",
      className: "left_price",
      align: "left",
      sortable: true,
    },
    {
      key: "right_price",
      text: "Right Shoulder Price",
      className: "right_price",
      align: "left",
      sortable: true,
    },
    {
      key: "dob",
      text: " Date of Birth",
      className: "dob",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.dob).format("ll")}</Fragment>;
      },
    },
    {
      key: "description",
      text: "Description",
      className: "description",
      align: "left",
      sortable: true,
    },

    {
      key: "player_image",
      text: "Player Image",
      className: "player_image",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a
              href={baseUrl + "./static/upload/" + record.player_image}
              target="_blank"
            >
              {" "}
              {record.player_image}{" "}
            </a>
          </Fragment>
        );
      },
    },

    {
      key: "jersey_image",
      text: "Jersey Image",
      className: "jersey_image",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a
              href={baseUrl + "./static/upload/" + record.jersey_image}
              target="_blank"
            >
              {" "}
              {record.jersey_image}{" "}
            </a>
          </Fragment>
        );
      },
    },

    {
      key: "view",
      text: "View",
      className: "views",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <Link to={"viewPlayers/" + record.id}>
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
              >
                View
              </button>
            </Link>
          </Fragment>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-user-modal"
              className="btn btn-primary btn-sm"
              onClick={() => openUpdateModal(record)}
              style={{ marginRight: "5px" }}
            >
              Update
            </button>{" "}
            <button
              data-toggle="modal"
              className="btn btn-primary btn-sm"
              onClick={() => Conn(record)}
              style={{ marginRight: "5px" }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };
  return (
    <>
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex flex-wrap justify-content-between align-items-center mb-4">
              <h2 className="text-black m-0">
                <b>Players Details</b>
              </h2>

              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#add-tickets"
                onClick={() => setModalIsOpen(true)}
              >
                Add +
              </button>
            </div>
            <div className="white_box">
              <div className="job-table-outer">
                <div className="table-responsive">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          className="job-form-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title> Add Players</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center">
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Player Name</label>
                  <input
                    className="form-control"
                    placeholder="Player Name"
                    type="text"
                    name="name"
                    onChange={handleChange101}
                    value={name}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Jersey No.</label>
                  <input
                    className="form-control fs12"
                    placeholder="Jersey No"
                    type="number"
                    name="jersey_no"
                    onChange={handleChange101}
                    value={jerseyNo}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label>Date Of Birth</label>
                  <input
                    className="form-control fs12"
                    type="date"
                    name="dob"
                    onChange={handleChange101}
                    value={dob}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Chest Sponsorship Price</label>
                  <input
                    className="form-control fs12"
                    placeholder="Chest Sponsorship Price"
                    type="number"
                    name="chest_price"
                    onChange={handleChange101}
                    value={chestPrice}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Back Sponsorship Price</label>
                  <input
                    className="form-control fs12"
                    placeholder="Back Sponsorship Price"
                    type="number"
                    name="back_price"
                    onChange={handleChange101}
                    value={backPrice}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Left Sponsorship Price</label>
                  <input
                    className="form-control fs12"
                    placeholder="Left Sponsorship Price"
                    type="number"
                    name="left_price"
                    onChange={handleChange101}
                    value={leftPrice}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Right Sponsorship Price</label>
                  <input
                    className="form-control fs12"
                    placeholder="Right Sponsorship Price"
                    type="number"
                    name="right_price"
                    onChange={handleChange101}
                    value={rightPrice}
                  />
                </div>
              </div>

              <div class="form-group col-md-6">
                <label>Player Image</label>
                <div id="file-upload-form" class="uploader">
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    onChange={handleChangeFImage}
                  />

                  <label for="file-upload" id="file-drag">
                    <img
                      id="file-image"
                      src={
                        userUploadPlayerImage !== false
                          ? userUploadPlayerImage
                          : dPic
                      }
                      alt="Player_image"
                      // class="hidden"
                    />
                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                </div>
                <p class="mt-0">*only jpeg, jpg &amp; png files supported</p>
                <div style={{ color: "red" }}>{errorPlayerImage}</div>
              </div>

              <div class="form-group col-md-6">
                <label>Jersey Image</label>
                <div id="file-upload-form" class="uploader">
                  <input
                    id="file-upload1"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    onChange={handleChangeJerseyImage}
                  />

                  <label for="file-upload1" id="file-drag">
                    <img
                      id="file-image"
                      src={
                        userUploadJerseyImage !== false
                          ? userUploadJerseyImage
                          : dPic
                      }
                      alt="Jersey_image"
                      // class="hidden"
                    />

                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                </div>
                <p class="mt-0">*only jpeg, jpg &amp; png files supported</p>
                <div style={{ color: "red" }}>{errorJerseyImage}</div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    placeholder="Enter Description"
                    className="form-control"
                    name="description"
                    onChange={handleChange101}
                    value={description}
                  ></textarea>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <div className="submit-btn-outer">
              <button type="submit" class="btn btn-primary" onClick={submit}>
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={modalIsOpenForUpdate}
          onHide={closeUpdateModal}
          className="job-form-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Players Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center">
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Player Name</label>
                  <input
                    className="form-control"
                    placeholder="Player Name"
                    type="text"
                    name="name"
                    onChange={handleChange101}
                    value={name}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Jersey No.</label>
                  <input
                    className="form-control fs12"
                    placeholder="Jersey No"
                    type="number"
                    name="jersey_no"
                    onChange={handleChange101}
                    value={jerseyNo}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label>Date Of Birth</label>
                  <input
                    className="form-control fs12"
                    type="date"
                    name="dob"
                    onChange={handleChange101}
                    value={dob}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Chest Sponsorship Price</label>
                  <input
                    className="form-control fs12"
                    placeholder="Chest Sponsorship Price"
                    type="number"
                    name="chest_price"
                    onChange={handleChange101}
                    value={chestPrice}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Back Sponsorship Price</label>
                  <input
                    className="form-control fs12"
                    placeholder="Back Sponsorship Price"
                    type="number"
                    name="back_price"
                    onChange={handleChange101}
                    value={backPrice}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Left Sponsorship Price</label>
                  <input
                    className="form-control fs12"
                    placeholder="Left Sponsorship Price"
                    type="number"
                    name="left_price"
                    onChange={handleChange101}
                    value={leftPrice}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Right Sponsorship Price</label>
                  <input
                    className="form-control fs12"
                    placeholder="Right Sponsorship Price"
                    type="number"
                    name="right_price"
                    onChange={handleChange101}
                    value={rightPrice}
                  />
                </div>
              </div>

              <div class="form-group col-md-6">
                <label>Player Image</label>
                <div id="file-upload-form" class="uploader">
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    onChange={handleChangeFImage}
                  />

                  <label for="file-upload" id="file-drag">
                    <img
                      id="file-image"
                      src={
                        userUploadPlayerImage !== false
                          ? userUploadPlayerImage
                          : dPic
                      }
                      alt="Player_image"
                      // class="hidden"
                    />
                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                </div>
                <p class="mt-0">*only jpeg, jpg &amp; png files supported</p>
                <div style={{ color: "red" }}>{errorPlayerImage}</div>
              </div>

              <div class="form-group col-md-6">
                <label>Jersey Image</label>
                <div id="file-upload-form" class="uploader">
                  <input
                    id="file-upload1"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    onChange={handleChangeJerseyImage}
                  />

                  <label for="file-upload1" id="file-drag">
                    <img
                      id="file-image"
                      src={
                        userUploadJerseyImage !== false
                          ? userUploadJerseyImage
                          : dPic
                      }
                      alt="Jersey_image"
                      // class="hidden"
                    />

                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                </div>
                <p class="mt-0">*only jpeg, jpg &amp; png files supported</p>
                <div style={{ color: "red" }}>{errorJerseyImage}</div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    placeholder="Enter Description"
                    className="form-control"
                    name="description"
                    onChange={handleChange101}
                    value={description}
                  ></textarea>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <div className="submit-btn-outer">
              <button type="submit" class="btn btn-primary" onClick={onUpdate}>
                Update
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer />
    </>
  );
};

export default SupportNEarn;
