import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import * as myConstList from "./BaseUrl";
import { Link } from "react-router-dom";
const baseUrl = myConstList.baseUrl;

function PrivilegeCardAdd(props) {
  const [country, setCountry] = useState("");
  const [amount, setAmount] = useState("");

  useEffect(() => {}, []);

  const handleChangeName = async (event) => {
    if (event.target.name === "country") {
      let eventValue = event.target.value;
      setCountry(eventValue);
    }
    if (event.target.name === "amount") {
      let eventValue = event.target.value;
      setAmount(eventValue);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let customerData = {
      country: country,
      amount: amount,
    };
    axios
      .post(baseUrl + "/adminapi/add-privilege", customerData)
      .then((res) => {
        var resp = res.data;
        if (resp.success === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.success === true) {
          toast.success(resp.message);

          setAmount("");
          setCountry("");

          setTimeout(function () {
            window.location = "/privilege-card";
          }, 3000);
          return false;
        }
      });
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2 className="text-black">
                <b>Add Privilege Card</b>
              </h2>
            </div>
            <div className="white_box">
              <div className="row">
                <div className="col-xxl-5">
                  <div className="add-product-outer">
                    <form>
                      <div className="row">
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Country</label>
                            <input
                              type="text"
                              name="country"
                              placeholder=""
                              className="form-control"
                              onChange={handleChangeName}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Amount</label>
                            <input
                              type="number"
                              name="amount"
                              placeholder=""
                              className="form-control"
                              onChange={handleChangeName}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="btn-group">
                      <button
                        type="button"
                        onClick={handleSubmit}
                        class="btn btn-primary"
                      >
                        {" "}
                        Add{" "}
                      </button>
                      <br />
                      <button
                        type="reset"
                        class="btn btn-primary"
                        onClick={() => {
                          window.location.href = "/privilege-card";
                        }}
                      >
                        {" "}
                        Cancel{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} />
    </div>
  );
}
export default PrivilegeCardAdd;
