import React, { Fragment, useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { baseUrl } from "./BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const Tickets = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenForUpdate, setModalIsOpenForUpdate] = useState(false);
  const [record, setRecords] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [venue, setVenue] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [price, setPrice] = useState("");
  const [ticket, setTicket] = useState("");
  const [id, setId] = useState("");
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [venueError, setVenueError] = useState("");
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [ticketError, setTicketError] = useState("");

  const [ticketImage, setTicketImage] = useState(false);
  const [userUploadTicketImage, setUserUploadTicketImage] = useState(
    "/assets/images/file.png"
  );
  const [errorTicketImage, setErrorTicketImage] = useState("");
  const dPic = "/assets/images/file.png";

  const closeModal = () => {
    setModalIsOpen(false);
    setName("");
    setDate("");
    setTime("");
    setVenue("");
    setPrice("");
    setTicket("");
    setDescription("");
    setUserUploadTicketImage(false);
  };

  const openUpdateModal = (item) => {
    if (item.ticket_image === null || typeof item.ticket_image == "undefined") {
      var ImageUrl = "/assets/images/file.png";
    } else {
      var ImageUrl = baseUrl + "/static/upload/" + item.ticket_image;
    }
    setUserUploadTicketImage(ImageUrl);
    setName(item.name);
    setVenue(item.venue);
    setDate(item.date);
    setTime(item.time);
    setPrice(item.price);
    setTicket(item.total_ticket);
    setId(item.id);
    setDescription(item.description);
    setModalIsOpenForUpdate(true);
  };

  const closeUpdateModal = () => {
    setModalIsOpenForUpdate(false);
    setName("");
    setDate("");
    setTime("");
    setVenue("");
    setPrice("");
    setTicket("");
    setDescription("");
    setUserUploadTicketImage(false);
  };
  const deleteRecord = (record) => {
    axios
      .post(baseUrl + "/adminapi/delete-ticket", { id: record.id })
      .then((resp) => {
        console.log("res[pp", resp);
        var respp = resp.data;
        if (respp.success === true) {
          toast.dismiss();
          getTickets();
          closeModal();
          toast.success(respp.message);
        } else {
          toast.dismiss();
          toast.error(respp.message);
        }
      });
  };

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(getMethodDeleteId),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  useEffect(() => {
    getTickets();
  }, []);

  const getTickets = () => {
    axios.post(baseUrl + "/adminapi/get-tickets-data").then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setRecords(respp.data);
      }
    });
  };

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      if (!value) {
        setName(value);
        setNameError("Please enter name");
        return false;
      }
      setName(value);
      setNameError("");
    }
    if (name === "date") {
      if (!value) {
        setDateError("Please enter date");
        setDate(value);
        return false;
      }
      setDate(value);
      setDateError("");
    }
    if (name === "time") {
      if (!value) {
        setTimeError("Please enter time");
        setTime(value);
        return false;
      }
      setTime(value);
      setTimeError("");
    }
    if (name === "venue") {
      if (!value) {
        setVenueError("Please enter venue");
        setVenue(value);
        return false;
      }
      setVenue(value);
      setVenueError("");
    }
    if (name === "price") {
      if (!value) {
        setPriceError("Please enter price");
        setPrice(value);
        return false;
      }
      setPrice(value);
      setPriceError("");
    }
    if (name === "ticket") {
      if (!value) {
        setTicketError("Please enter tickets");
        setTicket(value);
        return false;
      }
      setTicket(value);
      setTicketError("");
    }
    if (name === "description") {
      if (!value) {
        setDescriptionError("Please enter description");
        setDescription(value);
        return false;
      }
      setDescription(value);
      setDescriptionError("");
    }
  };

  const handleChangeTImage = (e) => {
    e.preventDefault();
    setTicketImage("/assets/images/file.png");

    var IdentityPic = e.target.files[0];
    setTicketImage(e.target.files[0]);
    setUserUploadTicketImage(URL.createObjectURL(IdentityPic));

    if (IdentityPic === false) {
      setUserUploadTicketImage(false);
      setErrorTicketImage("Please select valid image jpg|jpeg|png|gif");
      return false;
    }
    if (!IdentityPic.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setUserUploadTicketImage(false);
      setErrorTicketImage("Please select valid image jpg|jpeg|png|gif");
      return false;
    }
    if (IdentityPic.size >= 1000000 || IdentityPic.size < 10000) {
      setErrorTicketImage("Please select valid image jpg|jpeg|png|gif");
      return false;
    }
    // if (IdentityPic.size < 50000) {
    //     toast.error("File Size is less than 500KB")
    //     return false;
    // }
    setErrorTicketImage("");
    setUserUploadTicketImage(URL.createObjectURL(e.target.files[0]));
  };

  const submit = (e) => {
    e.preventDefault();

    if (!name && !date && !time && !venue && !price && !description) {
      setNameError("Please enter name");
      setDateError("Please enter date");
      setTimeError("Please enter time");
      setVenueError("Please enter venue");
      setPriceError("Please enter price");
      setDescriptionError("Please enter description");
      return false;
    }

    const TicketImage = ticketImage;

    const formData = new FormData();
    formData.append("TicketImage", TicketImage);
    formData.append("name", name);
    formData.append("date", date);
    formData.append("time", time);
    formData.append("venue", venue);
    formData.append("price", price);
    formData.append("ticket", ticket);
    formData.append("description", description);

    axios.post(baseUrl + "/adminapi/add-tickets", formData).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        closeModal();
        getTickets();
        toast.success(respp.message);
        return false;
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };
  const onUpdate = () => {
    const TicketImage = ticketImage;

    const formData = new FormData();
    formData.append("TicketImage", TicketImage);
    formData.append("name", name);
    formData.append("date", date);
    formData.append("time", time);
    formData.append("venue", venue);
    formData.append("price", price);
    formData.append("ticket", ticket);
    formData.append("description", description);
    formData.append("id", id);

    axios.post(baseUrl + "/adminapi/update-ticket", formData).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        closeUpdateModal();
        getTickets();
        toast.dismiss();
        toast.success(respp.message);
        return false;
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Event Name",
      className: "username",
      align: "left",
      sortable: true,
    },
    {
      key: "date",
      text: "Event Date",
      className: "email",
      align: "left",
      sortable: true,
    },

    {
      key: "time",
      text: "Event Time",
      className: "phone",
      align: "left",
      sortable: true,
    },
    {
      key: "venue",
      text: "Event venue",
      className: "status",
      align: "left",
      sortable: true,
    },
    {
      key: "price",
      text: "Event Price",
      className: "price",
      align: "left",
      sortable: true,
    },
    {
      key: "total_ticket",
      text: "No. of Tickets",
      className: "tickets",
      align: "left",
      sortable: true,
    },
    {
      key: "description",
      text: "Description",
      className: "description",
      align: "left",
      sortable: true,
    },
    {
      key: "view",
      text: "View",
      className: "view",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <Link to={"viewTickets/" + record.id}>
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
              >
                View
              </button>
            </Link>
          </Fragment>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action d-flex",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-user-modal"
              className="btn btn-primary btn-sm"
              onClick={() => openUpdateModal(record)}
              style={{ marginRight: "5px" }}
            >
              Update
            </button>
            <button
              data-toggle="modal"
              className="btn btn-primary btn-sm"
              onClick={() => Conn(record)}
              style={{ marginRight: "5px" }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };
  return (
    <>
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex flex-wrap justify-content-between align-items-center mb-4">
              <h2 className="text-black m-0">
                <b>Tickets Form</b>
              </h2>
              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#add-tickets"
                onClick={() => setModalIsOpen(true)}
              >
                Add +
              </button>
            </div>
            <div className="white_box">
              <div className="job-table-outer">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          className="job-form-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title> Tickets Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center">
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Event Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder="Name"
                    onChange={handleChange101}
                    value={name}
                  />
                </div>
                <div style={{ color: "red" }}>{nameError}</div>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Event Date</label>
                  <input
                    className="form-control fs12"
                    type="date"
                    name="date"
                    onChange={handleChange101}
                    value={date}
                  />
                </div>
                {/* <div style={{ color: "red" }}>{dateError}</div> */}
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Event Time</label>
                  <input
                    className="form-control fs12"
                    type="time"
                    name="time"
                    onChange={handleChange101}
                    value={time}
                  />
                </div>
                {/* <div style={{ color: "red" }}>{timeError}</div> */}
              </div>
              <div className="col-sm-4 col-12">
                <div className="form-group">
                  <label>Event Venue</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="venue"
                    placeholder="Venue"
                    onChange={handleChange101}
                    value={venue}
                  />
                </div>
                {/* <div style={{ color: "red" }}>{venueError}</div> */}
              </div>
              <div className="col-sm-4 col-12">
                <div className="form-group">
                  <label>Ticket Price</label>
                  <input
                    className="form-control fs12"
                    type="number"
                    name="price"
                    placeholder="Price"
                    onChange={handleChange101}
                    value={price}
                  />
                </div>
                {/* <div style={{ color: "red" }}>{priceError}</div> */}
              </div>
              <div className="col-sm-4 col-12">
                <div className="form-group">
                  <label>No. of Tickets</label>
                  <input
                    className="form-control fs12"
                    type="number"
                    name="ticket"
                    placeholder="Tickets"
                    onChange={handleChange101}
                    value={ticket}
                  />
                </div>
                {/* <div style={{ color: "red" }}>{ticketError}</div> */}
              </div>
              <div className="col-sm-12 col-12">
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    placeholder="Enter Description"
                    className="form-control"
                    name="description"
                    onChange={handleChange101}
                    value={description}
                  ></textarea>
                </div>
                {/* <div style={{ color: "red" }}>{descriptionError}</div> */}
              </div>
              <div class="form-group col-md-6">
                <label>Event Image</label>
                <div id="file-upload-form" class="uploader">
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    onChange={handleChangeTImage}
                  />

                  <label for="file-upload" id="file-drag">
                    <img
                      id="file-image"
                      src={
                        userUploadTicketImage !== false
                          ? userUploadTicketImage
                          : dPic
                      }
                      alt="Event_image"
                    />
                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                </div>
                <p class="mt-0">*only jpeg, jpg &amp; png files supported</p>
                <div style={{ color: "red" }}>{errorTicketImage}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <div className="submit-btn-outer">
              <button type="submit" class="btn btn-primary" onClick={submit}>
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={modalIsOpenForUpdate}
          onHide={closeUpdateModal}
          className="job-form-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title> Tickets Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center">
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Event Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={handleChange101}
                    value={name}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Event Date</label>
                  <input
                    className="form-control fs12"
                    type="date"
                    name="date"
                    onChange={handleChange101}
                    value={date}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Event Time</label>
                  <input
                    className="form-control fs12"
                    type="time"
                    name="time"
                    onChange={handleChange101}
                    value={time}
                  />
                </div>
              </div>
              <div className="col-sm-4 col-12">
                <div className="form-group">
                  <label>Event Venue</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="venue"
                    onChange={handleChange101}
                    value={venue}
                  />
                </div>
              </div>
              <div className="col-sm-4 col-12">
                <div className="form-group">
                  <label>Ticket Price</label>
                  <input
                    className="form-control fs12"
                    type="number"
                    name="price"
                    onChange={handleChange101}
                    value={price}
                  />
                </div>
              </div>
              <div className="col-sm-4 col-12">
                <div className="form-group">
                  <label>No. of Tickets</label>
                  <input
                    className="form-control fs12"
                    type="number"
                    name="ticket"
                    placeholder="Tickets"
                    onChange={handleChange101}
                    value={ticket}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-12">
                <div className="form-group">
                  <label>Description</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="description"
                    onChange={handleChange101}
                    value={description}
                  />
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Event Image</label>
                <div id="file-upload-form" class="uploader">
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    onChange={handleChangeTImage}
                  />

                  <label for="file-upload" id="file-drag">
                    <img
                      id="file-image"
                      src={
                        userUploadTicketImage !== false
                          ? userUploadTicketImage
                          : dPic
                      }
                      alt="Event_image"
                      // class="hidden"
                    />
                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                </div>
                <p class="mt-0">*only jpeg, jpg &amp; png files supported</p>
                <div style={{ color: "red" }}>{errorTicketImage}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <div className="submit-btn-outer">
              <button type="submit" class="btn btn-primary" onClick={onUpdate}>
                Update
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer />
    </>
  );
};

export default Tickets;
