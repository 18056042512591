import React, { Fragment, useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import { baseUrl } from "./BaseUrl";

const Promote = () => {
  const [record, setRecords] = useState([]);

  useEffect(() => {
    getPromote();
  }, []);

  const getPromote = () => {
    axios.post(baseUrl + "/adminapi/get-promote-data").then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setRecords(respp.data);
      }
    });
  };

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "phone",
      text: "Phone No.",
      className: "phone",
      align: "left",
      sortable: true,
    },
    {
      key: "country",
      text: "Country",
      className: "country",
      align: "left",
      sortable: true,
    },
    {
      key: "state",
      text: "State",
      className: "State",
      align: "left",
      sortable: true,
    },
    {
      key: "city",
      text: "City",
      className: "City",
      align: "left",
      sortable: true,
    },
    {
      key: "zipcode",
      text: "Zipcode",
      className: "Zipcode",
      align: "left",
      sortable: true,
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  return (
    <>
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex flex-wrap justify-content-between align-items-center mb-4">
              <h2 className="text-black m-0">
                <b>Promote n Earn</b>
              </h2>
            </div>
            <div className="white_box">
              <div className="job-table-outer">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Promote;
