import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "./BaseUrl";

function Sidebar(props) {
  useEffect(() => {}, []);

  console.log(window.location.pathname);

  return (
    <div id="scrollbar">
      <div class="simplebar-content" style={{ padding: "0px" }}>
        <div className="container-fluid p-0">
          <ul className="navbar-nav" id="navbar-nav">
            <li className="menu-title">
              <span data-key="t-menu">Menu</span>
            </li>
            <li className="nav-item">
              <Link
                to={"/dashboard"}
                className={
                  window.location.pathname == "/dashboard"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="mdi mdi-speedometer" />{" "}
                <span data-key="t-dashboards">Dashboards</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/user-management"}
                className={
                  window.location.pathname == "/user-management"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i class="mdi mdi-account"></i>
                <span data-key="t-dashboards">User Management</span>
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="mdi mdi-account-check  "></i>
                <span data-key="t-dashboards">KYC Management</span>
              </a>
              <ul
                className={
                  window.location.pathname == "/pending-kyc" ||
                  window.location.pathname == "/verified-kyc" ||
                  window.location.pathname == "/order-completed"
                    ? "dropdown-menu position-static show"
                    : "dropdown-menu position-static"
                }
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link
                    to={"/pending-kyc"}
                    className={
                      window.location.pathname == "/pending-kyc"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    <i class="mdi mdi-account-check mr-2"></i>
                    Pending KYC
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      window.location.pathname == "/verified-kyc"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    to={"/verified-kyc"}
                  >
                    <i class="mdi mdi-account-check mr-2"></i>
                    Verified KYC
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                to={"/privilege-history"}
                className={
                  window.location.pathname == "/privilege-history"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i class="mdi mdi-history"></i>
                <span data-key="t-dashboards">Privilege History</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/job-form"}
                className={
                  window.location.pathname == "/job-form"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i class="mdi mdi-briefcase"></i>
                <span data-key="t-dashboards">Job Management</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/tickets"}
                className={
                  window.location.pathname == "/tickets"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="mdi mdi-ticket"></i>
                <span data-key="t-dashboards">Tickets</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/support-earn"}
                className={
                  window.location.pathname == "/support-earn"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i class="mdi mdi-headphones"></i>
                <span data-key="t-dashboards">Support & Earn</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/privilege-card"}
                className={
                  window.location.pathname == "/privilege-card"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i class="mdi mdi-ticket-account"></i>
                <span data-key="t-dashboards">Privilege Card</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to={"/play-earn"}
                className={
                  window.location.pathname == "/play-earn"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i class="mdi mdi-cash"></i>

                <span data-key="t-dashboards">Play N Earn</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to={"/learn-earn"}
                className={
                  window.location.pathname == "/learn-earn"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i class="mdi mdi-cash"></i>

                <span data-key="t-dashboards">Learn N Earn</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to={"/promote-earn"}
                className={
                  window.location.pathname == "/promote-earn"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i class="mdi mdi-cash"></i>

                <span data-key="t-dashboards">Promote N Earn</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
