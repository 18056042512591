import React, { Fragment, useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { baseUrl } from "./BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const JobForm = () => {
  const [id, setId] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenForUpdate, setModalIsOpenForUpdate] = useState(false);
  const [record, setRecords] = useState([]);
  const [name, setName] = useState("");
  const [vacancy, setVacancy] = useState("");
  const [experience, setExperience] = useState("");
  const [salary, setSalary] = useState("");
  const [location, setLocation] = useState("");
  const [details, setDetails] = useState("");
  const [nameError, setNameError] = useState("");
  const [vacancyError, setVacancyError] = useState("");
  const [experienceError, setExperienceError] = useState("");
  const [salaryError, setSalaryError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [detailsError, setDetailsError] = useState("");
  const [jobImage, setJobImage] = useState(false);
  const [userUploadJobImage, setUserUploadJobImage] = useState(
    "/assets/images/file.png"
  );
  const [errorJobImage, setErrorJobImage] = useState("");
  const dPic = "/assets/images/file.png";

  useEffect(() => {
    getJobs();
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
    setName("");
    setVacancy("");
    setExperience("");
    setDetails("");
    setSalary("");
    setLocation("");
    setId("");
    setNameError("");
    setVacancyError("");
    setExperienceError("");
    setSalaryError("");
    setLocationError("");
    setDetailsError("");
    setUserUploadJobImage(false);
  };

  const openUpdateModal = (item) => {
    if (item.job_image === null || typeof item.job_image == "undefined") {
      var ImageUrl = "/assets/images/file.png";
    } else {
      var ImageUrl = baseUrl + "/static/upload/" + item.job_image;
    }

    setName(item.job_name);
    setVacancy(item.vacancy);
    setExperience(item.experience);
    setDetails(item.details);
    setSalary(item.salary);
    setLocation(item.location);
    setUserUploadJobImage(ImageUrl);
    setId(item.id);

    setModalIsOpenForUpdate(true);
  };

  const closeUpdateModal = () => {
    setModalIsOpenForUpdate(false);
    setName("");
    setVacancy("");
    setExperience("");
    setDetails("");
    setSalary("");
    setLocation("");
    setId("");
    setUserUploadJobImage(false);
  };
  const deleteRecord = (record) => {
    axios
      .post(baseUrl + "/adminapi/delete-job", { id: record.id })
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          getJobs();
          closeModal();
          toast.success(respp.message);
        }
      });
  };

  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(getMethodDeleteId),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };

  const onUpdate = () => {
    const JobImage = jobImage;

    const formData = new FormData();
    formData.append("JobImage", JobImage);
    formData.append("job_name", name);
    formData.append("salary", salary);
    formData.append("experience", experience);
    formData.append("vacancy", vacancy);
    formData.append("location", location);
    formData.append("details", details);
    formData.append("id", id);

    axios.post(baseUrl + "/adminapi/update-job", formData).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        closeUpdateModal();
        getJobs();
        toast.dismiss();
        toast.success(respp.message);
        return false;
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };

  const getJobs = () => {
    axios.post(baseUrl + "/adminapi/get-job").then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setRecords(respp.data);
      }
    });
  };

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      if (!value) {
        setNameError("Please enter job post");
        setName(value);
        return false;
      }
      setName(value);
      setNameError("");
    }
    if (name === "salary") {
      if (!value) {
        setSalaryError("Please enter salary");
        setSalary(value);
        return false;
      }
      setSalary(value);
      setSalaryError("");
    }
    if (name === "experience") {
      if (!value) {
        setExperienceError("Please enter experience");
        setExperience(value);
        return false;
      }
      setExperience(value);
      setExperienceError("");
    }
    if (name === "location") {
      if (!value) {
        setLocationError("Please enter location");
        setLocation(value);
        return false;
      }
      setLocation(value);
      setLocationError("");
    }
    if (name === "vacancy") {
      if (!value) {
        setVacancyError("Please enter vacancy");
        setVacancy(value);
        return false;
      }
      setVacancy(value);
      setVacancyError("");
    }
    if (name === "details") {
      if (!value) {
        setDetailsError("Please enter details");
        setDetails(value);
        return false;
      }
      setDetails(value);
      setDetailsError("");
    }
  };

  const handleChangeJImage = (e) => {
    e.preventDefault();
    setJobImage("/assets/images/file.png");

    var IdentityPic = e.target.files[0];
    setJobImage(e.target.files[0]);

    setUserUploadJobImage(URL.createObjectURL(IdentityPic));

    if (IdentityPic === false) {
      setUserUploadJobImage(false);
      setErrorJobImage("Please select valid image jpg|jpeg|png|gif");

      return false;
    }
    if (!IdentityPic.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setUserUploadJobImage(false);
      setErrorJobImage("Please select valid image jpg|jpeg|png|gif");

      return false;
    }
    if (IdentityPic.size >= 1000000 || IdentityPic.size < 10000) {
      setErrorJobImage("Please select valid image jpg|jpeg|png|gif");
      return false;
    }
    // if (IdentityPic.size < 50000) {
    //     toast.error("File Size is less than 500KB")
    //     return false;
    // }
    setErrorJobImage("");
    setUserUploadJobImage(URL.createObjectURL(e.target.files[0]));
  };

  const submit = (e) => {
    e.preventDefault();

    if (!name && !salary && !experience && !vacancy && !location && !details) {
      setNameError("Please enter job post");
      setSalaryError("Please enter salary");
      setExperienceError("Please enter experience");
      setVacancyError("Please enter vacancy");
      setLocationError("Please enter location");
      setDetailsError("Please enter details");
      return false;
    }

    if (!name || name === undefined) {
      setNameError("Please enter job post");
      return false;
    }

    if (!salary || salary === undefined) {
      setSalaryError("Please enter salary");
      return false;
    }

    if (!experience || experience === undefined) {
      setExperienceError("Please enter experience");
      return false;
    }

    if (!vacancy || vacancy === undefined) {
      setVacancyError("Please enter vacancy");
    }

    if (!location || location === undefined) {
      setLocationError("Please enter location");
      return false;
    }

    if (!details || details === undefined) {
      setDetailsError("Please enter details");
      return false;
    }

    if (jobImage === false) {
      toast.error("Please select valid an image");
      return false;
    }
    if (!jobImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      toast.error("Please select valid image jpg|jpeg|png|gif");
      return false;
    }
    if (jobImage.size >= 1000000 || jobImage.size < 10000) {
      toast.error("Please select valid image jpg|jpeg|png|gif");
      return false;
    }

    const JobImage = jobImage;

    const formData = new FormData();
    formData.append("JobImage", JobImage);
    formData.append("job_name", name);
    formData.append("salary", salary);
    formData.append("experience", experience);
    formData.append("vacancy", vacancy);
    formData.append("location", location);
    formData.append("details", details);

    axios.post(baseUrl + "/adminapi/add-job", formData).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        setName("");
        setSalary("");
        setExperience("");
        setLocation("");
        setVacancy("");
        setDetails("");
        getJobs();
        toast.success(respp.message);
        return false;
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "job_name",
      text: "Job Post",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "vacancy",
      text: "Vacancies",
      className: "vacancy",
      align: "left",
      sortable: true,
    },
    {
      key: "experience",
      text: "Experience",
      className: "experience",
      align: "left",
      sortable: true,
    },
    {
      key: "salary",
      text: "Salary",
      className: "salary",
      align: "left",
      sortable: true,
    },
    {
      key: "details",
      text: "Details",
      className: "details",
      align: "left",
      sortable: true,
    },
    {
      key: "location",
      text: "Location",
      className: "location",
      align: "left",
      sortable: true,
    },
    {
      key: "view",
      text: "View",
      className: "view",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <Link to={"job-details/" + record.id}>
              <button className="btn btn-primary">View</button>
            </Link>
          </Fragment>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action d-flex",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-user-modal"
              className="btn btn-primary btn-sm"
              onClick={() => openUpdateModal(record)}
              style={{ marginRight: "5px" }}
            >
              Update
            </button>
            <button
              data-toggle="modal"
              className="btn btn-primary btn-sm"
              onClick={() => Conn(record)}
              style={{ marginRight: "5px" }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };
  return (
    <>
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex flex-wrap justify-content-between align-items-center mb-4">
              <h2 className="text-black m-0">
                <b>Job Form</b>
              </h2>
              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#add-user-modal"
                onClick={() => setModalIsOpen(true)}
              >
                Add +
              </button>
            </div>
            <div className="white_box">
              <div className="job-table-outer">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          className="job-form-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title> Job Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center">
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Job Post</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={handleChange101}
                    value={name}
                  />
                </div>
                <div style={{ color: "red" }}>{nameError}</div>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Salary</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="salary"
                    onChange={handleChange101}
                    value={salary}
                  />
                </div>
                <div style={{ color: "red" }}>{salaryError}</div>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Experience</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="experience"
                    onChange={handleChange101}
                    value={experience}
                  />
                </div>
                <div style={{ color: "red" }}>{experienceError}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Vacancy</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="vacancy"
                    onChange={handleChange101}
                    value={vacancy}
                  />
                </div>
                <div style={{ color: "red" }}>{vacancyError}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Location</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="location"
                    onChange={handleChange101}
                    value={location}
                  />
                </div>
                <div style={{ color: "red" }}>{locationError}</div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Details</label>
                  <textarea
                    placeholder="Enter Details"
                    className="form-control"
                    name="details"
                    onChange={handleChange101}
                    value={details}
                  ></textarea>
                </div>
                <div style={{ color: "red" }}>{detailsError}</div>
              </div>
              <div class="form-group col-md-6">
                <label>Job Image</label>
                <div id="file-upload-form" class="uploader">
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    onChange={handleChangeJImage}
                  />

                  <label for="file-upload" id="file-drag">
                    <img
                      id="file-image"
                      src={
                        userUploadJobImage !== false ? userUploadJobImage : dPic
                      }
                      alt="Job_image"
                      // class="hidden"
                    />
                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                </div>
                <p class="mt-0">*only jpeg, jpg &amp; png files supported</p>
                <div style={{ color: "red" }}>{errorJobImage}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <div className="submit-btn-outer">
              <button type="submit" class="btn btn-primary" onClick={submit}>
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={modalIsOpenForUpdate}
          onHide={closeUpdateModal}
          className="job-form-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title> Tickets Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center">
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Job Post</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={handleChange101}
                    value={name}
                  />
                </div>
                <div style={{ color: "red" }}>{nameError}</div>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Salary</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="salary"
                    onChange={handleChange101}
                    value={salary}
                  />
                </div>
                <div style={{ color: "red" }}>{salaryError}</div>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Experience</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="experience"
                    onChange={handleChange101}
                    value={experience}
                  />
                </div>
                <div style={{ color: "red" }}>{experienceError}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Vacancy</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="vacancy"
                    onChange={handleChange101}
                    value={vacancy}
                  />
                </div>
                <div style={{ color: "red" }}>{vacancyError}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Location</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="location"
                    onChange={handleChange101}
                    value={location}
                  />
                </div>
                <div style={{ color: "red" }}>{locationError}</div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Details</label>
                  <textarea
                    placeholder="Enter Details"
                    className="form-control"
                    name="details"
                    onChange={handleChange101}
                    value={details}
                  ></textarea>
                </div>
                <div style={{ color: "red" }}>{detailsError}</div>
              </div>
              <div class="form-group col-md-6">
                <label>Job Image</label>
                <div id="file-upload-form" class="uploader">
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    onChange={handleChangeJImage}
                  />

                  <label for="file-upload" id="file-drag">
                    <img
                      id="file-image"
                      src={
                        userUploadJobImage !== false ? userUploadJobImage : dPic
                      }
                      alt="Job_image"
                      // class="hidden"
                    />
                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                </div>
                <p class="mt-0">*only jpeg, jpg &amp; png files supported</p>
                <div style={{ color: "red" }}>{errorJobImage}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <div className="submit-btn-outer">
              <button type="submit" class="btn btn-primary" onClick={onUpdate}>
                Update
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer />
    </>
  );
};

export default JobForm;
