import React from "react";

function Footer() {
  return (
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">{new Date().getFullYear()} © Procur.</div>
          <div class="col-sm-6">
            <div class="text-sm-end d-none d-sm-block">
              Design & Develop by IPKC.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
