import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import * as myConstList from "./BaseUrl";
import { useParams } from "react-router-dom";

const baseUrl = myConstList.baseUrl;

function PrivilegeCardEdit(props) {
  const [id, setId] = useState(useParams().id);
  const [countryError, setCountryError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [country, setCountry] = useState("");
  const [amount, setAmount] = useState("");

  useEffect(() => {}, []);

  const handleChangeName = async (event) => {
    let { name, value } = event.target;

    if (name === "country") {
      if (!value) {
        setCountryError("Country required");
        setCountry(value);
        return false;
      }
      setCountry(value);
      setCountryError("");
    }

    if (name === "amount") {
      if (!value) {
        setAmountError("Amount required");
        setAmount(value);
        return false;
      }
      setAmount(value);
      setAmountError("");
    }
  };

  const handleOnBlurName = async (event) => {
    let { name, value } = event.target;

    if (name === "country") {
      if (!value) {
        toast.dismiss();
        toast.error("Country required");
        return false;
      }
    }
    if (name === "amount") {
      if (!value) {
        toast.dismiss();
        toast.error("Amount required");
        return false;
      }
    }
  };

  const handleSubmit = async (event) => {
    if (event.key === "Enter") {
    }
    event.preventDefault();
    let customerData = {
      id: id,
      country: country,
      amount: amount,
    };
    axios
      .post(baseUrl + "/adminapi/privilege-card-update", customerData)
      .then((res) => {
        var resp = res.data;
        if (resp.success === false) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        if (resp.success === true) {
          toast.success("Privilege Card Updated Successfully");
          setTimeout(function () {
            window.location = "/privilege-card";
          }, 3000);
          return false;
        }
      });
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2 className="text-black">
                <b>Category Manager Update</b>
              </h2>
            </div>
            <div className="white_box">
              <div className="row">
                <div className="col-xxl-5">
                  <div className="add-product-outer">
                    <form>
                      <div className="row">
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Country</label>
                            <input
                              type="text"
                              name="country"
                              value={country}
                              placeholder=""
                              className="form-control"
                              onChange={handleChangeName}
                              onBlur={handleOnBlurName}
                            />
                          </div>
                          {/* <div style={{ color: "red" }}>{countryError}</div> */}
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="form-group">
                            <label>Amount</label>
                            <input
                              type="text"
                              name="amount"
                              value={amount}
                              placeholder=""
                              className="form-control"
                              onChange={handleChangeName}
                              onBlur={handleOnBlurName}
                            />
                          </div>
                          {/* <div style={{ color: "red" }}>{amountError}</div> */}
                        </div>
                      </div>
                    </form>
                    <div className="btn-group">
                      <button
                        type="button"
                        onClick={handleSubmit}
                        class="btn btn-primary"
                      >
                        {" "}
                        Save{" "}
                      </button>
                      <br />
                      <button type="button" class="btn btn-primary">
                        {" "}
                        Cancel{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} />
    </div>
  );
}
export default PrivilegeCardEdit;
