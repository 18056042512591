import React, { Fragment, useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { baseUrl } from "./BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const LearnNEarn = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenForUpdate, setModalIsOpenForUpdate] = useState(false);
  const [modalIsOpenForAdd, setModalIsOpenForAdd] = useState(false);
  const [record, setRecords] = useState([]);
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [startingDate, setStartingDate] = useState("");
  const [zoomLink, setZoomLink] = useState("");
  const [fees, setFees] = useState("");
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  const [errorImage, setErrorImage] = useState("");
  const [image, setImage] = useState(false);
  const [userUploadImage, setUserUploadImage] = useState(
    "/assets/images/file.png"
  );
  const dPic = "/assets/images/file.png";

  const closeModal = () => {
    setModalIsOpen(false);
    setUserUploadImage(false);
  };

  const openUpdateModal = (item) => {
    if (item.image === null || typeof item.image == "undefined") {
      var ImageUrl = "/assets/images/file.png";
    } else {
      var ImageUrl = baseUrl + "/static/upload/" + item.image;
    }

    setName(item.course_name);
    setDuration(item.duration);
    setStartingDate(item.starting_date);
    setFees(item.fees);
    setDescription(item.description);
    setZoomLink(item.zoom_link);
    setUserUploadImage(ImageUrl);
    setId(item.id);
    setModalIsOpenForUpdate(true);
  };

  const openAddModal = (item) => {
    setZoomLink(item.zoom_link);
    setId(item.id);
    setModalIsOpenForAdd(true);
  };
  const closeAddModal = () => {
    setModalIsOpenForAdd(false);
  };
  const closeUpdateModal = () => {
    setName("");
    setDuration("");
    setStartingDate("");
    setFees("");
    setDescription("");
    setZoomLink("");
    setUserUploadImage(false);
    setModalIsOpenForUpdate(false);
  };
  const deleteRecord = (record) => {
    axios
      .post(baseUrl + "/adminapi/delete-course", { id: record.id })
      .then((resp) => {
        var respp = resp.data;
        console.log("sdfbjhsdfsdsdsdvjhd", respp);
        if (respp.success === true) {
          getCourses();
          toast.dismiss();
          closeModal();
          toast.success(respp.message);
          return false;
        }
        toast.dismiss();
        toast.error(respp.message);
      });
  };
  const checkCall = () => {
    return false;
  };

  const Conn = (getMethodDeleteId) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(getMethodDeleteId),
        },
        {
          label: "No",
          onClick: () => checkCall(),
        },
      ],
    });
  };
  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = () => {
    axios.post(baseUrl + "/adminapi/get-course-data").then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setRecords(respp.data);
      }
    });
  };

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      setName(value);
      return false;
    }
    if (name === "duration") {
      setDuration(value);
      return false;
    }
    if (name === "starting_date") {
      setStartingDate(value);
      return false;
    }
    if (name === "fees") {
      setFees(value);
      return false;
    }
    if (name === "zoom_link") {
      setZoomLink(value);
      return false;
    }
    if (name === "description") {
      setDescription(value);
      return false;
    }
  };

  const handleChangeImage = (e) => {
    e.preventDefault();
    setImage("/assets/img/icon2.png");

    var IdentityPic = e.target.files[0];
    setImage(e.target.files[0]);

    setUserUploadImage(URL.createObjectURL(IdentityPic));

    if (IdentityPic === false) {
      setUserUploadImage(false);
      setErrorImage("Please select valid image jpg|jpeg|png|gif");

      return false;
    }
    if (!IdentityPic.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setUserUploadImage(false);
      setErrorImage("Please select valid image jpg|jpeg|png|gif");

      return false;
    }
    if (IdentityPic.size >= 1000000 || IdentityPic.size < 10000) {
      setErrorImage("Please select valid image jpg|jpeg|png|gif");
      return false;
    }
    setUserUploadImage(URL.createObjectURL(e.target.files[0]));
  };

  const submit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("CourseImage", image);
    formData.append("course_name", name);
    formData.append("starting_date", startingDate);
    formData.append("duration", duration);
    formData.append("fees", fees);
    formData.append("description", description);

    axios.post(baseUrl + "/adminapi/add-course", formData).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        closeModal();
        getCourses();
        toast.success(respp.message);
        return false;
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };

  const onAddZoomLink = () => {
    if (!zoomLink) {
      toast.dismiss();
      toast.error("Zoom Link required");
      return false;
    }
    const data = {
      id: id,
      zoom_link: zoomLink,
    };
    axios
      .post(baseUrl + "/adminapi/update-course-zoomlink", data)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          closeAddModal();
          getCourses();
          toast.dismiss();
          toast.success(respp.message);
          return false;
        }
        toast.dismiss();
        toast.error(respp.message);
      });
  };

  const onUpdate = () => {
    const formData = new FormData();

    formData.append("CourseImage", image);
    formData.append("course_name", name);
    formData.append("starting_date", startingDate);
    formData.append("duration", duration);
    formData.append("fees", fees);
    formData.append("description", description);
    formData.append("id", id);

    axios.post(baseUrl + "/adminapi/update-course", formData).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        closeUpdateModal();
        getCourses();
        toast.dismiss();
        toast.success(respp.message);
        return false;
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "course_name",
      text: "Course Name",
      className: "course_name",
      align: "left",
      sortable: true,
    },
    {
      key: "fees",
      text: "Fees",
      className: "fees",
      align: "left",
      sortable: true,
    },
    {
      key: "duration",
      text: "Duration",
      className: "duration",
      align: "left",
      sortable: true,
    },
    {
      key: "starting_date",
      text: "Starting Date",
      className: "starting_date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.starting_date).format("ll")}</Fragment>;
      },
    },

    {
      key: "image",
      text: "Course image",
      className: "img",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a
              href={baseUrl + "./static/upload/" + record.image}
              target="_blank"
            >
              {" "}
              {record.image}{" "}
            </a>
          </Fragment>
        );
      },
    },
    {
      key: "description",
      text: "Description",
      className: "description",
      align: "left",
      sortable: true,
    },
    {
      key: "zoom_link",
      text: "Zoom Link",
      className: "zoom_link",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.zoom_link}{" "}
            <button
              data-toggle="modal"
              data-target="#update-user-modal"
              className="btn btn-primary btn-sm"
              onClick={() => openAddModal(record)}
              style={{ marginRight: "5px" }}
            >
              Add
            </button>
          </Fragment>
        );
      },
    },
    {
      key: "view",
      text: "View",
      className: "view",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <Link to={"viewCourse/" + record.id}>
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
              >
                View
              </button>
            </Link>
          </Fragment>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action d-flex",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-user-modal"
              className="btn btn-primary btn-sm"
              onClick={() => openUpdateModal(record)}
              style={{ marginRight: "5px" }}
            >
              Update
            </button>
            <button
              data-toggle="modal"
              className="btn btn-primary btn-sm"
              onClick={() => Conn(record)}
              style={{ marginRight: "5px" }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };
  return (
    <>
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex flex-wrap justify-content-between align-items-center mb-4">
              <h2 className="text-black m-0">
                <b>Courses</b>
              </h2>
              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#add-tickets"
                onClick={() => setModalIsOpen(true)}
              >
                Add +
              </button>
            </div>
            <div className="white_box">
              <div className="job-table-outer">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          className="job-form-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Course Form </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Course Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={handleChange101}
                    value={name}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-12">
                <div className="form-group">
                  <label>Course Fees</label>
                  <input
                    className="form-control fs12"
                    type="number"
                    name="fees"
                    onChange={handleChange101}
                    value={fees}
                  />
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Course Duration</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="duration"
                    onChange={handleChange101}
                    value={duration}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Course Date</label>
                  <input
                    className="form-control fs12"
                    type="date"
                    name="starting_date"
                    onChange={handleChange101}
                    value={startingDate}
                  />
                </div>
              </div>

              <div class="form-group col-md-6">
                <div id="file-upload-form" class="uploader">
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    onChange={handleChangeImage}
                  />

                  <label for="file-upload" id="file-drag">
                    <img
                      id="file-image"
                      src={userUploadImage !== false ? userUploadImage : dPic}
                      alt="Preview"
                      // class="hidden"
                    />
                    <div id="start">
                      <span class="fasi">
                        <i class="las la-cloud-upload-alt"></i>
                      </span>
                      <div>Upload Document</div>
                      <div id="notimage" class="hidden">
                        Please select an image
                      </div>
                    </div>
                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                </div>
                <p class="mt-0">*only jpeg, jpg &amp; png files supported</p>
                <div style={{ color: "red" }}>{errorImage}</div>
              </div>

              <div className="col-sm-12 col-12">
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    placeholder="Enter Description"
                    className="form-control"
                    name="description"
                    onChange={handleChange101}
                    value={description}
                  ></textarea>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <div className="submit-btn-outer">
              <button type="submit" class="btn btn-primary" onClick={submit}>
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Add Modal for ZOOM LINK */}

        <Modal
          show={modalIsOpenForAdd}
          onHide={closeAddModal}
          className="job-form-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title> Update Zoom Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center">
              <div className="col-sm-12 col-12">
                <div className="form-group">
                  <label>Zoom Link</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="zoom_link"
                    onChange={handleChange101}
                    value={zoomLink}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <div className="submit-btn-outer">
              <button
                type="submit"
                class="btn btn-primary"
                onClick={onAddZoomLink}
              >
                Add
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* MOdal For UPDATE */}

        <Modal
          show={modalIsOpenForUpdate}
          onHide={closeUpdateModal}
          className="job-form-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title> Update Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Course Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={handleChange101}
                    value={name}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Course Starting Date</label>
                  {console.log("hiloo", startingDate)}
                  <input
                    className="form-control fs12"
                    type="date"
                    name="starting_date"
                    onChange={handleChange101}
                    value={startingDate}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <label>Course Duration</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="duration"
                    onChange={handleChange101}
                    value={duration}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Course Fees</label>
                  <input
                    className="form-control fs12"
                    type="number"
                    name="fees"
                    onChange={handleChange101}
                    value={fees}
                  />
                </div>
              </div>
              <div class="form-group col-md-12">
                <div id="file-upload-form" class="uploader">
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    onChange={handleChangeImage}
                  />

                  <label for="file-upload" id="file-drag">
                    <img
                      id="file-image"
                      src={userUploadImage !== false ? userUploadImage : dPic}
                      alt="Preview"
                      // class="hidden"
                    />
                    <div id="start">
                      <span class="fasi">
                        <i class="las la-cloud-upload-alt"></i>
                      </span>
                      <div>Upload Document</div>
                      <div id="notimage" class="hidden">
                        Please select an image
                      </div>
                    </div>
                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                </div>
                <p class="mt-0">*only jpeg, jpg &amp; png files supported</p>
                <div style={{ color: "red" }}>{errorImage}</div>
              </div>

              <div className="col-sm-12 col-12">
                <div className="form-group">
                  <label>Description</label>
                  <input
                    className="form-control fs12"
                    type="text"
                    name="description"
                    onChange={handleChange101}
                    value={description}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block">
            <div className="submit-btn-outer">
              <button type="submit" class="btn btn-primary" onClick={onUpdate}>
                Update
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer />
    </>
  );
};

export default LearnNEarn;
