import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import axios from "axios";

import { baseUrl } from "./BaseUrl";

const ShowPendingKyc = () => {
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [errorReject, setErrorReject] = useState("");
  const [rejectType, setRejectType] = useState("");

  const [btnId, setBtnId] = useState("3");

  const [btnP, setBtnP] = useState("3");
  const [btnS, setBtnS] = useState("3");

  const [userUploadFImage, setUserUploadFImage] = useState(
    "/assets/images/auth-one-bg.jpg"
  );
  const [userUploadBImage, setUserUploadBImage] = useState(
    "/assets/images/auth-one-bg.jpg"
  );
  const [userUploadPImage, setUserUploadPImage] = useState(
    "/assets/images/auth-one-bg.jpg"
  );
  const [userUploadSImage, setUserUploadSImage] = useState(
    "/assets/images/auth-one-bg.jpg"
  );

  const dPic = "/assets/images/auth-one-bg.jpg";

  let { id } = useParams();
  let { show } = useParams();

  useEffect(() => {
    getUserData();
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
    setRejectReason("");
  };

  const getUserData = () => {
    const data = {
      id: id,
    };
    axios.post(baseUrl + "/adminapi/get-userById", data).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setData(respp.data);

        if (
          respp.data.selfie === null ||
          typeof respp.data.selfie == "undefined"
        ) {
          var ImageUrl = "/assets/images/auth-one-bg.jpg";
        } else {
          var ImageUrl = baseUrl + "/static/upload/" + respp.data.selfie;
        }

        // Front Image //
        if (
          respp.data.fImage === null ||
          typeof respp.data.fImage == "undefined"
        ) {
          var ImageUrl2 = "/assets/images/auth-one-bg.jpg";
        } else {
          var ImageUrl2 = baseUrl + "/static/upload/" + respp.data.fImage;
        }
        if (
          respp.data.bImage === null ||
          typeof respp.data.bImage == "undefined"
        ) {
          var ImageUrl3 = "/assets/images/auth-one-bg.jpg";
        } else {
          var ImageUrl3 = baseUrl + "/static/upload/" + respp.data.bImage;
        }

        // Pancard Image //
        if (
          respp.data.panImage === null ||
          respp.data.panImage == "undefined"
        ) {
          var ImageUrl4 = "/assets/images/auth-one-bg.jpg";
        } else {
          var ImageUrl4 = baseUrl + "/static/upload/" + respp.data.panImage;
        }

        setUserUploadFImage(ImageUrl2);
        setUserUploadBImage(ImageUrl3);
        setUserUploadPImage(ImageUrl4);
        setUserUploadSImage(ImageUrl);

        // if (
        //   respp.data.fimage_status === "1" &&
        //   respp.data.bimage_status === "1"
        // ) {
        // }
        // if (respp.data.pan_image_status === "1") {
        setBtnId(respp.data.bimage_status);
        setBtnP(respp.data.pan_image_status);
        setBtnS(respp.data.selfie_status);
        // } else {
        // setBtnP("0");
        // }
        // if (respp.data.selfie_status === "1") {
        // }
      }
    });
  };

  const reject = (e) => {
    e.preventDefault();
    if (rejectType === "d1") {
      var stype = "d1";
      var stype2 = "d2";

      const data = {
        _id: id,
        fimage_reject_reason: rejectReason,
        bimage_reject_reason: rejectReason,
        stype: stype,
        stype2: stype2,
      };

      axios
        .post(baseUrl + "/adminapi/rejectStatus-update", data)
        .then((resp) => {
          var respp = resp.data;
          if (respp.success === true) {
            toast.dismiss();
            toast.success(respp.message);
            getUserData();
            closeModal();
            return false;
          } else {
            toast.dismiss();
            toast.error(respp.message);
          }
        });
    }

    if (rejectType === "d3") {
      var stype3 = "d3";

      const data = {
        _id: id,
        pan_reject_reason: rejectReason,
        stype: stype3,
      };

      axios
        .post(baseUrl + "/adminapi/rejectStatus-update", data)
        .then((resp) => {
          var respp = resp.data;
          if (respp.success === true) {
            toast.dismiss();
            toast.success(respp.message);
            getUserData();
            closeModal();
            return false;
          } else {
            toast.dismiss();
            toast.error(respp.message);
          }
        });
    }

    if (rejectType === "d4") {
      var stype4 = "d4";

      const data = {
        _id: id,
        selfie_reject_reason: rejectReason,
        stype: stype4,
      };

      axios
        .post(baseUrl + "/adminapi/rejectStatus-update", data)
        .then((resp) => {
          var respp = resp.data;
          if (respp.success === true) {
            toast.dismiss();
            toast.success(respp.message);
            getUserData();
            closeModal();
            return false;
          } else {
            toast.dismiss();
            toast.error(respp.message);
          }
        });
    }
  };

  const verify = (e) => {
    e.preventDefault();
    var type = e.target.name;

    if (type === "d1") {
      var stype = "d1";
      var stype2 = "d2";

      const data = {
        _id: id,
        stype: stype,
        stype2: stype2,
      };

      axios
        .post(baseUrl + "/adminapi/verifyStatus-update", data)
        .then((resp) => {
          var respp = resp.data;
          if (respp.success === true) {
            toast.dismiss();
            toast.success(respp.message);
            getUserData();
            return false;
          } else {
            toast.dismiss();
            toast.error(respp.message);
          }
        });
    }

    if (type === "d3") {
      var stype3 = "d3";

      const data = {
        _id: id,
        stype: stype3,
      };

      axios
        .post(baseUrl + "/adminapi/verifyStatus-update", data)
        .then((resp) => {
          var respp = resp.data;
          if (respp.success === true) {
            toast.dismiss();
            toast.success(respp.message);
            getUserData();
            return false;
          } else {
            toast.dismiss();
            toast.error(respp.message);
          }
        });
    }

    if (type === "d4") {
      var stype4 = "d4";

      const data = {
        _id: id,
        stype: stype4,
      };

      axios
        .post(baseUrl + "/adminapi/verifyStatus-update", data)
        .then((resp) => {
          var respp = resp.data;
          if (respp.success === true) {
            toast.dismiss();
            toast.success(respp.message);
            getUserData();
            return false;
          } else {
            toast.dismiss();
            toast.error(respp.message);
          }
        });
    }
  };
  const handleChangeReject = (e) => {
    let value = e.target.value;
    if (!value) {
      setErrorReject("This field is required");
      setRejectReason(value);
      return false;
    }
    setErrorReject("");
    setRejectReason(value);
  };
  const openFunc = (type) => {
    setRejectType(type);
    setModalIsOpen(true);
  };

  return (
    <>
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2 className="text-black">
                <b>Verify Kyc</b>
              </h2>
            </div>
            <Modal
              show={modalIsOpen}
              onHide={closeModal}
              className="job-form-popup"
            >
              <Modal.Header closeButton>
                <Modal.Title> Reject Reason </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row align-items-center">
                  <div className="form-group col-12">
                    <input
                      className="col-md-12"
                      type="text"
                      value={rejectReason}
                      placeholder="Reject Reason"
                      onChange={handleChangeReject}
                    />
                  </div>
                  {errorReject}
                </div>
              </Modal.Body>
              <Modal.Footer className="d-block">
                <div className="submit-btn-outer">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    onClick={reject}
                  >
                    Submit
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <div className="vrify-kyc-main">
              <div className="verify-kyc-outer">
                <div className="row">
                  <div className="col-md-6 col-12 detail-inner-main">
                    <div className="kyc-detail-inner d-flex flex-wrap">
                      <h5>Name :</h5>
                      <p>
                        {data.fname} {data.lname === "NULL" ? "" : data.lname}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 detail-inner-main">
                    <div className="kyc-detail-inner d-flex flex-wrap">
                      <h5>DOB :</h5>
                      <p>{data.dob}</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 detail-inner-main">
                    <div className="kyc-detail-inner d-flex flex-wrap">
                      <h5>City :</h5>
                      <p>{data.city}</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 detail-inner-main">
                    <div className="kyc-detail-inner d-flex flex-wrap">
                      <h5>State :</h5>
                      <p>{data.state}</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 detail-inner-main">
                    <div className="kyc-detail-inner d-flex flex-wrap">
                      <h5>Country :</h5>
                      <p>{data.res_country}</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 detail-inner-main">
                    <div className="kyc-detail-inner d-flex flex-wrap">
                      <h5>Zipcode :</h5>
                      <p>{data.zipcode}</p>
                    </div>
                  </div>
                  <div className="col-12 detail-inner-main">
                    <div className="kyc-detail-inner d-flex flex-wrap">
                      <h5>Address :</h5>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="verify-kyc-outer">
                <div className="row">
                  <div className="mobile-order-1 col-md-6 col-12 detail-inner-main">
                    <div className="passport-detail-inner d-flex flex-wrap">
                      <h5>
                        {data.docType === "passport" ? "Passport Number" : ""}{" "}
                      </h5>
                      <h5>
                        {data.docType === "nationalId" ? "National Id" : ""}{" "}
                      </h5>
                      <h5>
                        {data.docType === "driving" ? "Driving License" : ""}{" "}
                      </h5>
                      <h5>
                        {data.docType === "aadhaar" ? "Aadhaar Number" : ""}{" "}
                      </h5>
                      <p>{data.document_number}</p>
                    </div>
                  </div>
                  <div className="mobile-order-4 col-md-6 col-12 detail-inner-main">
                    <div className="passport-detail-inner d-flex flex-wrap">
                      <h5>Pancard Number :</h5>
                      <p>{data.pan_number}</p>
                    </div>
                  </div>
                  <div className="mobile-order-2 col-md-3 col-12">
                    <div className="document-outer">
                      {btnId === "0" ? (
                        <h5
                          style={{
                            color: "red",
                          }}
                        >
                          Rejected
                        </h5>
                      ) : (
                        ""
                      )}
                      <h5>Identity Document</h5>
                      <div className="document-inner d-flex flex-wrap">
                        <h5>Front Image :</h5>
                        <div className="document-thumbnail">
                          <a href="#!">
                            <img
                              src={
                                userUploadFImage !== false
                                  ? userUploadFImage
                                  : dPic
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>

                      {show === "1" && (
                        <div>
                          {btnId === "1" && (
                            <div className="reject-btn-outer btn-group">
                              <a
                                href="#!"
                                className="btn btn-primary"
                                name="d1"
                                onClick={(e) => openFunc(e.target.name)}
                              >
                                Rejected
                              </a>
                              <a
                                href="#!"
                                className="btn btn-primary"
                                name="d1"
                                onClick={verify}
                              >
                                Verify
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mobile-order-3 col-md-3 col-12">
                    <div className="document-outer">
                      {btnId === "0" ? (
                        <h5
                          style={{
                            color: "red",
                          }}
                        >
                          Rejected
                        </h5>
                      ) : (
                        ""
                      )}
                      <h5>Identity Document</h5>
                      <div className="document-inner d-flex flex-wrap">
                        <h5>Back Image :</h5>
                        <div className="document-thumbnail">
                          <a href="#!">
                            <img
                              src={
                                userUploadBImage !== false
                                  ? userUploadBImage
                                  : dPic
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-order-5 col-md-3 col-12">
                    <div className="document-outer">
                      {btnP === "0" ? (
                        <h5
                          style={{
                            color: "red",
                          }}
                        >
                          Rejected
                        </h5>
                      ) : (
                        ""
                      )}
                      <h5>Pancard :</h5>
                      <div className="document-inner d-flex flex-wrap">
                        <h5>Image :</h5>
                        <div className="document-thumbnail">
                          <a href="#!">
                            <img
                              src={
                                userUploadPImage !== false
                                  ? userUploadPImage
                                  : dPic
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                      {show === "1" && (
                        <div>
                          {btnP === "1" && (
                            <div className="reject-btn-outer btn-group">
                              <a
                                href="#!"
                                className="btn btn-primary"
                                name="d3"
                                onClick={(e) => openFunc(e.target.name)}
                              >
                                Rejected
                              </a>
                              <a
                                href="#!"
                                className="btn btn-primary"
                                name="d3"
                                onClick={verify}
                              >
                                Verify
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mobile-order-6 col-md-3 col-12">
                    <div className="document-outer">
                      {btnS === "0" ? (
                        <h5
                          style={{
                            color: "red",
                          }}
                        >
                          Rejected
                        </h5>
                      ) : (
                        ""
                      )}
                      <h5>Selfie :</h5>
                      <div className="document-inner d-flex flex-wrap">
                        <h5>Image :</h5>
                        <div className="document-thumbnail">
                          <a href="#!">
                            <img
                              src={
                                userUploadSImage !== false
                                  ? userUploadSImage
                                  : dPic
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>

                      {show === "1" && (
                        <div>
                          {btnS === "1" && (
                            <div className="reject-btn-outer btn-group">
                              <a
                                href="#!"
                                className="btn btn-primary"
                                name="d4"
                                onClick={(e) => openFunc(e.target.name)}
                              >
                                Rejected
                              </a>
                              <a
                                href="#!"
                                className="btn btn-primary"
                                name="d4"
                                onClick={verify}
                              >
                                Verify
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ShowPendingKyc;
